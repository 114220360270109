import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpjMask',
  standalone: true
})
export class CpfCnpjMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const onlyNumbersAndStars = value.replace(/[^0-9*]/g, '');

    if (onlyNumbersAndStars.length === 11) {
      return onlyNumbersAndStars.replace(/^(\*{3}|\d{3})(\d{3})(\d{3})(\*{2}|\d{2})$/, '***.$2.$3-**');
    } else if (onlyNumbersAndStars.length === 14) {
      return onlyNumbersAndStars.replace(/^(\*{2}|\d{2})(\d{3})(\d{3})(\d{4})(\*{2}|\d{2})$/, '**.$2.$3/$4-**');
    }

    return value;
  }
}
