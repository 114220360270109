<header class="top-navbar">
  <div class="fixed">
    <div class="d-flex justify-content-between align-items-center" [style.display]="this.headerStyle">
      <div class="profile-info d-flex align-items-center">
        <div class="profile-text">
          <span class="profile-name">{{ completeUserName }}</span>
          <span class="profile-cpf">
            {{ cpfUser | cpfCnpjMask }} <span *ngIf="hasAccount">- Agência: {{ agencyNumber }} - Conta: {{ accountNumber | accountMask }} </span> 
          </span>
          
        </div>
      </div>

      <div class="m-header d-flex align-items-center">
        <a class="mobile-menu" id="mobile-collapse1" href="javascript:" (click)="this.NavCollapsedMob.emit()"><span></span></a>
      </div>
    </div>
  </div>
</header>

<div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
