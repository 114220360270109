<div class="card" [ngClass]="cardClass" [@cardRemove]="cardRemove" #toAnimate>
  @if (!hidHeader) {
    <div class="card-header" [ngClass]="headerClass">
      @if (!customHeader) {
        <h5>{{ cardTitle }}</h5>
      }
      @if (this.options && !customHeader) {
        <div class="card-header-right">
          <div class="btn-group card-option dropdown" ngbDropdown>
            <button type="button" class="btn dropdown-toggle btn-icon" ngbDropdownToggle>
              <i class="feather icon-more-horizontal"></i>
            </button>
            <ul class="list-unstyled card-option dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <li
                class="dropdown-item full-card"
                (click)="fullCardToggle(toAnimate, '', true)"
                role="none"
                (keypress)="fullCardToggle(toAnimate, '', true)"
              >
                <a href="javascript:"
                  ><span
                    ><i class="feather" [ngClass]="fullIcon"></i> {{ this.cardClass === 'full-card' ? 'Restore' : 'Maximize' }}</span
                  ></a
                >
              </li>
              <li class="dropdown-item minimize-card" (click)="collapsedCardToggle()" role="none" (keypress)="collapsedCardToggle()">
                <a href="javascript:"
                  ><span
                    ><i class="feather" [ngClass]="collapsedIcon"></i>
                    {{ this.collapsedCard === 'collapsed' ? 'Expand' : 'Collapse' }}
                  </span>
                  <span style="display: none"><i class="feather icon-plus"></i></span
                ></a>
              </li>
              <li class="dropdown-item reload-card" (click)="cardRefresh()" role="none" (keypress)="cardRefresh()">
                <a href="javascript:"><i class="feather icon-refresh-cw"></i> Reload</a>
              </li>
              <li class="dropdown-item close-card" (click)="cardRemoveAction()" role="none" (keypress)="cardRemoveAction()">
                <a href="javascript:"><i class="feather icon-trash"></i> Remove</a>
              </li>
            </ul>
          </div>
        </div>
      }
      @if (customHeader) {
        <ng-content select=".app-card-header"></ng-content>
      }
    </div>
  }
  <div [@collapsedCard]="collapsedCard">
    <div class="card-block" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </div>
  @if (loadCard) {
    <div class="card-loader">
      <i class="pct-loader1 anim-rotate"></i>
    </div>
  }
</div>
