// Angular import
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

// project import
import { ThemeService } from '../../../service/theme.service';

// rxjs
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-animation-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './animation-modal.component.html',
  styleUrls: ['./animation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnimationModalComponent implements OnInit, OnDestroy {
  // public props
  @Input() modalClass!: string;
  @Input() contentClass!: string;
  @Input() modalID!: string;
  @Input() backDrop = false;
  themeSub = new Subscription();
  isDarkTheme: string;
  private subs: Subscription[] = [];
  // constructor
  constructor(private themeService: ThemeService) {}

  // life cycle method
  ngOnInit() {
    this.subs.push( this.themeService.isDarkTheme.subscribe((isDark) => {
      this.isDarkTheme = isDark;
    }));
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close(event);
    }
  }

  close(event) {
    (document.querySelector('#' + event) as HTMLElement).classList.remove('md-show');
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    })
  }
}
