import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme = new ReplaySubject<string>(1);
  isDarkTheme = new ReplaySubject<string>(1);
}
