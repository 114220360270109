<app-navigation
  class="pcoded-navbar"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'theme-horizontal': this.config['layout'] === 'horizontal',
    'mob-open': navCollapsedMob
  }"
  (NavCollapse)="this.navCollapsed = !this.navCollapsed"
  (NavCollapsedMob)="navMobClick()"
/>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (NavCollapsedMob)="navMobClick()" />
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <app-breadcrumb />
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
</div>

