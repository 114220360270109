import { InfoPayments } from "./user";

export class InfoPaymentsGenerator {
  static createInfoPayments(): InfoPayments {
    return {
      lastPayments: [],
      monthPayments: [
        { month: "janeiro", payments: 0, amount: 0 },
        { month: "fevereiro", payments: 0, amount: 0 },
        { month: "março", payments: 0, amount: 0 },
        { month: "abril", payments: 0, amount: 0 },
        { month: "maio", payments: 0, amount: 0 },
        { month: "junho", payments: 0, amount: 0 }
      ],
      paymentStatus: [
        { status: "AWAITING_AUTHORIZATION", quantity: 0 },
        { status: "APPROVED", quantity: 0 },
        { status: "REJECTED", quantity: 0 }
      ],
      weeklyPayments: [
        { day: "domingo", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "sábado", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "sexta-feira", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "quinta-feira", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "quarta-feira", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "terça-feira", ammountApproveds: 0, ammountRejectds: 0 },
        { day: "segunda-feira", ammountApproveds: 0, ammountRejectds: 0 }
      ],
      totalPayments: 0
    };
  }
}

