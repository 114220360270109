import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PinRequest, PinResponse, Reset, ResetPasswordToken, Token, TokenPay, User } from '../_helpers/user';
import { environment } from 'src/environments/environment';
import { changeAccountInfo } from 'src/app/demo/Model/changeAccountInfo';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private apiUrl = environment.apiUrl;
  private loginApiUrl = `/management-business/v1/user/login`;
  private resetApiUrl = `/management-business/v1/user/password/forgot`;
  private registerApiUrl = `/management-business/v1/user/new`;
  private changePasswordApiUrl = `/management-business/v1/user/password/edit`;
  private editStatusAccountUrl = `/management-business/v1/user/edit/active`
  private getClientAndSecretUrl = `/management-business/v1/user/token/find?externalId=`;
  private validateResetTokenUrl = `/management-business/v1/user/validate/reset-token?`
  private changePasswordByTokenUrl = `/management-business/v1/user/password`
  private savePin = `/management-business/v1/user/pin`
  private validatePin = `/management-business/v1/user/validate/pin`
  private changePinEndpoint = `/management-business/v1/user/pin`
  private resetPinEndpoint = `/management-business/v1/user/pin/forgot`
  private resetPersonalDataCustomer = `/management-business/v1/user/personal-data`
  private sub = new Subscription();

  constructor(private http: HttpClient) {}

  login(userName: string, password: string): Observable<User> {
    return this.http.post<User>(this.loginApiUrl, { userName, password }).pipe(
      map((user) => {
        return user;
      })
    );
  }

  reset(userName: string): Observable<Reset>{
    return this.http.post<Reset>(this.resetApiUrl, {userName}).pipe(
      map(( reset ) => {
        return reset;
      })
    );
  }

  register(userData: any): Observable<any> {
    return this.http.post<any>(this.registerApiUrl, userData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changePassword(userData: any): Observable<any> {
    return this.http.put<any>(this.changePasswordApiUrl, userData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  activeCustomerAccout(userData: any): Observable<any>{
    return this.http.post<any>(this.editStatusAccountUrl, userData).pipe(
      map(
        (response) => {
          return response;
        }
      )
    )

  }

  getClientAndSecret(externalId: String): Observable<Token>{
    return this.http.get<Token>(this.getClientAndSecretUrl + externalId)
  }


  validateResetToken(token: string, email: string){
    const urlWithParameters = this.validateResetTokenUrl + new HttpParams().set('token', token).set('email', email).toString();
    return this.http.get<boolean>(urlWithParameters)
  }

  changePasswordByToken(passwordResetRequest: ResetPasswordToken): Observable<any>{
    return this.http.post<ResetPasswordToken>(this.changePasswordByTokenUrl, passwordResetRequest)
  }

  savePinUser(data: PinRequest): Observable<PinResponse>{
    return this.http.post<PinResponse>(this.savePin, data)
  }

  validatePinUser(data: PinRequest): Observable<TokenPay>{
    return this.http.post<TokenPay>(this.validatePin, data)
  }

  changePinUser(data: any): Observable<PinResponse>{
    return this.http.patch<PinResponse>(this.changePinEndpoint, data)
  }

  resetPinUser(externalId: string, data: any): Observable<PinResponse>{
    const url = `${this.resetPinEndpoint}?externalId=${externalId}`
    return this.http.patch<PinResponse>(url, data)
  }

  changePersonalData(externalId: string, data: changeAccountInfo ): Observable<changeAccountInfo>{
    const url = `${this.resetPersonalDataCustomer}?externalId=${externalId}`
    return this.http.patch<changeAccountInfo>(url, data)
  }
}
