import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  private subs: Subscription[] = [];

  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {

    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;

    if (isLoggedIn) {
      if(currentUser.transactionToken){
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
            'X-external-id': `{"externalId": "${currentUser.externalId}","idCustomer": "${currentUser.idCustumer}"}`,
            'X-transaction-token': currentUser.transactionToken
          }
        });

      }

      else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
          'X-external-id': `{"externalId": "${currentUser.externalId}","idCustomer": "${currentUser.idCustumer}"}`,
        }
      });
    }
    }

    return next.handle(request);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    })
  }
}
