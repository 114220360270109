export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}
export const NavigationItems: NavigationItem[] = [
  {
    id: 'navigation',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'home',
        title: 'Home',
        icon: '../../../../../../assets/images/widget/home.svg',
        type: 'item',
        url: '/dashboard/home'
      },
      {
        id: 'conta',
        title: 'Minha Conta',
        icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
        type: 'item',
        url: '/dashboard/home'
      },
      {
        id: 'pix',
        title: 'Pix',
        icon: '../../../../../../assets/images/widget/pix-icon.svg',
        type: 'collapse', 
        children: [
          {
           id: 'transferir',
           title: 'Transferir',
           type: 'item',
           url: '/pix/send',
          },
          {
            id: 'receber-pix',
            title: 'Receber',
            type: 'item',
            url: '/pix/qrcode',
          },
          {
            id: 'minhas-chaves',
            title: 'Minhas Chaves',
            type: 'item',
            url: '/pix/key',
          },
          // {
          //    id: 'limite-pix',
          //    title: 'Limites Pix',
          //    type: 'item',
          //    url: '/pix/limit',
          // },
          {
            id: 'atendimento-pix',
            title: 'Atendimento',
            type: 'item',
            url: '/pix/atendimento',
         },
        ]
      },
      // {
      //   id: 'filial',
      //   title: 'Filiais',
      //   icon: '../../../../../../assets/images/widget/company-icon.svg',
      //   type: 'item',
      //   url: '/cadastrar/Filial/Empresa'
      // },
      // {
      //   id: 'account',
      //   title: 'Conta Pagamentos',
      //   icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
      //   type: 'item',
      //   url: '/cadastro/Conta/Pagamento'
      // },
      // {
      //   id: 'cliente',
      //   title: 'Clientes',
      //   icon: '../../../../../../assets/images/widget/users.svg',
      //   type: 'item',
      //   url: '/Cadastrar/Clientes/Empresa'
      // },
      {
        id: 'links',
        classes: 'link-start',
        title: 'Gerar Links',
        icon: '../../../../../../assets/images/widget/icon-export.svg',
        type: 'item',
        url: '/Pagamento/cliente/Link'
      },
      
      /* {
        id: 'boleto',
        classes: 'link-end',
        title: 'Boletos',
        icon: '../../../../../../assets/images/widget/icon-boleto.svg',
        type: 'collapse', 
        children: [
          {
            id: 'gerar-boleto',
            title: 'Gerar novo boleto',
            type: 'item',
            url: '/checkout/boleto/payment/checkout/boleto',
          },
          {
            id: 'meus-boletos',
            title: 'Meus boletos',
            type: 'item',
            url: '/checkout/boleto/payment/checkout/boleto/list',
          }
          
        ]
      }, */
      
      {
        id: 'products',
        classes: 'products',
        title: 'Produtos',
        icon: '../../../../../../assets/images/widget/icon-export.svg',
        type: 'item',
        url: '/Cadastrar/Clientes/Produto'
      },
      {
        id: 'chave',
        classes: 'link-end',
        title: 'Chaves de Acesso',
        icon: '../../../../../../assets/images/widget/key.svg',
        type: 'item',
        url: '/integracao/api/chave'
      }
    ]
  }
];