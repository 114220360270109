<div class="md-modal" [ngClass]="modalClass" id="{{ modalID }}">
  <div class="md-content" [ngClass]="contentClass" [ngClass]="isDarkTheme === 'dark' ? 'bg-dark' : ''">
    <ng-content></ng-content>
  </div>
</div>
@if (backDrop) {
  <div class="md-overlay"></div>
} @else {
  <div aria-hidden="true" class="md-overlay" (click)="close(modalID)" (keydown)="handleKeyDown($event)"></div>
}
