@if (isSpinnerVisible) {
  <div id="http-loader">
    <div class="loader-bg">
      <!-- material-line -->
      @if (spinner === Spinkit.skLine) {
        <div class="sk-line-material" [class.colored]="!backgroundColor">
          <div class="sk-child sk-bounce1" [style.background-color]="backgroundColor"></div>
        </div>
      }
    </div>
  </div>
}
