import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, finalize, map, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  private lastRequestUrl: string | null = null;
  private lastRequestMethod: string | null = null;
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
      this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
      this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
      return this.requestInFlight$.asObservable();
  }

  setLastRequestUrl(url: string) {
    this.lastRequestUrl = url;
  }

  getLastRequestUrl(): string | null {
    return this.lastRequestUrl;
  }
  getLastRequestMethod(): string | null {
    return this.lastRequestMethod;
  }
}


@Injectable()
export class HTTPListener implements HttpInterceptor {
    private _requests = 0;

    constructor(private status: HttpInterceptorService,
                private router: Router,
                private authenticationService: AuthenticationService
                ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.status.setLastRequestUrl(req.url);
        ++this._requests;
        this.status.setHttpStatus(true);

        return next.handle(req).pipe(
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<any>) => {
            if (event.status === 200) {
              const authorization = event.headers.get('Authorization');
              const payAuthorization = event.headers.get('X-transaction-token')
              if (authorization) {
                const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
                let updatedUser = {
                    ...currentUser,
                    token: authorization
                };

                if(payAuthorization){
                  updatedUser = {
                    ...currentUser,
                    token: authorization,
                    transactionToken: payAuthorization
                  }
                }

                localStorage.setItem('currentUser', JSON.stringify(updatedUser));

                this.authenticationService.updateCurrentUser(updatedUser);
            }

            return event;
          }}),
            catchError(error => {
                if (error.status === 401) {
                    this.router.navigate(['/AcessoNegado']);
                }

                return throwError(error);
            }),
            finalize(() => {
                --this._requests;
                this.status.setHttpStatus(this._requests > 0);
            })
        );
    }
}
