@if (!item.hidden) {
  @if (item.url && !item.external) {
    <li [ngClass]="item.classes" [routerLinkActive]="['active']">
      <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
        <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
        @if (item.icon) {
          <span class="pcoded-mtext">{{ item.title | translate }}</span>
        } @else {
          {{ item.title | translate }}
        }
        <ng-template #directTitle>
          {{ item.title | translate }}
        </ng-template>
        <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
      </a>
    </li>
  }
  @if (item.url && item.external) {
    <li [ngClass]="item.classes">
      <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
        <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
        @if (item.icon) {
          <span class="pcoded-mtext">{{ item.title | translate }}</span>
        } @else {
          {{ item.title | translate }}
        }
        <ng-template #directTitle>
          {{ item.title | translate }}
        </ng-template>
        <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
      </a>
    </li>
  }
  <ng-template #itemIcon>
    @if (item.icon) {
      <span class="pcoded-micon">
        <img [src]="item.icon" alt="{{ item.title }}" class="custom-icon" />
      </span>
    }
  </ng-template>
  <ng-template #itemBadge>
    @if (item.badge && themeLayout === 'vertical') {
      <span class="pcoded-badge label" [ngClass]="item.badge.type">
        {{ item.badge.title | translate }}
      </span>
    }
    @if (item.badge && themeLayout === 'horizontal') {
      <span class="badge label" [ngClass]="item.badge.type">
        {{ item.badge.title | translate }}
      </span>
    }
  </ng-template>
}


