import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountMask',
  standalone: true
})
export class AccountPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const onlyNumbers = value.replace(/[^0-9]/g, '');

    if (onlyNumbers.length <= 1) {
      return onlyNumbers;
    }

    return onlyNumbers.slice(0, -1) + '-' + onlyNumbers.slice(-1);
  }
}
