@if (!item.hidden) {
  <!-- Horizontal layouts -->
  @if (themeLayout === 'horizontal') {
    <li
      (mouseenter)="navCollapse($event)"
      data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
      class="nav-item pcoded-hasmenu"
      [routerLinkActive]="['active']"
      style="background-color: #f5f7f9;"
    >
      <a
        [routerLinkActive]="['active']"
        href="javascript:"
        class="nav-link"
      >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
  }
  

  <!-- vertical layouts -->
  @if (themeLayout === 'vertical') {
    <li
      data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
      class="nav-item pcoded-hasmenu link-end"
      [routerLinkActive]="['active']"
    >
      <a
        [routerLinkActive]="['active']"
        href="javascript:"
        class="nav-link"
        (click)="navCollapse($event)"
      >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
  }
  
  <ng-template #itemContent>
    @if (item.icon) {
      <span class="pcoded-micon">
        <img class="custom-icon" *ngIf="item.icon.endsWith('.svg')" [src]="item.icon" alt="{{ item.title }}">
      </span>
    }
    <span class="pcoded-mtext"
      >{{ item.title | translate }}
      @if (item.badge && themeLayout === 'horizontal') {
        <span class="badge label" [ngClass]="item.badge.type">
          {{ item.badge.title }}
        </span>
      }
    </span>
    @if (item.badge && themeLayout === 'vertical') {
      <span class="pcoded-badge label" [ngClass]="item.badge.type">
        {{ item.badge.title | translate }}
      </span>
    }
  </ng-template>
  <ng-template #subMenuContent>
    <ul class="pcoded-submenu" [routerLinkActive]="['active']">
      @for (item of item.children; track item) {
        @if (item.type === 'item') {
          <app-nav-item [item]="item" />
        }
        @if (item.type === 'collapse') {
          <app-nav-collapse [item]="item" />
        }
      }
    </ul>
  </ng-template>
}
